import React, { useEffect, useState, useRef } from 'react';
import debounce from 'lodash.debounce';

const PuppeteerEmulation = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const wsRef = useRef(null);
    const lastClickedXPath = useRef('');
    const keypressBuffer = useRef([]);
    const [shouldPauseRendering, setShouldPauseRendering] = useState(false); // New flag for pausing rendering
    //const [contentKey, setContentKey] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const [successFlag, setSuccessFlag] = useState(false);
    const [startFlag, setStartFlag] = useState(true);
    const [dataFlag, setDataFlag] = useState(false);
    const lastBlurValueRef = useRef({});
    // let test = 0;
    const previouslyHighlightedElementRef = useRef(null);

    // Function to create an XPath for a given element
    const getElementXPath = (element) => {
        if (element.id !== '') {
            return `id("${element.id}")`;
        }
        if (element === document.body) return element.tagName;
        let ix = 0;
        const siblings = element.parentNode.childNodes;
        for (let i = 0; i < siblings.length; i++) {
            const sibling = siblings[i];
            if (sibling === element) return `${getElementXPath(element.parentNode)}/${element.tagName}[${++ix}]`;
            if (sibling.nodeType === 1 && sibling.tagName === element.tagName) ix++;
        }
    };

    // Debounced function to send keypress events to the server
    const debouncedSendKeyPresses = useRef(debounce(() => {
        if (keypressBuffer.current.length > 0) {
            wsRef.current.send(JSON.stringify({
                type: 'keyPresses',
                keypresses: keypressBuffer.current,
            }));
            keypressBuffer.current = [];
        }
    }, 300)).current;



    // Effect hook to establish WebSocket connection
    useEffect(() => {
        wsRef.current = new WebSocket('wss://insta-unicorn-bot-2-0b62dd63b1fe.herokuapp.com/');



        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);


    useEffect(() => {

        wsRef.current.onmessage = (event) => {
            //console.log(test);
            console.log(shouldPauseRendering);
            const { type, data } = JSON.parse(event.data);
            if (type === 'html' && !shouldPauseRendering) {
                console.log('html data recevied');
                let cleanedHtmlContent = data.replace(/<div\s+id="splash-screen".*?<\/div>/gi, ''); // Remove divs with id "splash-screen"
                cleanedHtmlContent = cleanedHtmlContent.replace(/<a\s+aria-label="Get it on Google Play".*?<\/a>/gi, ''); // Remove divs with class "x78zum5 xdt5ytf"
                cleanedHtmlContent = cleanedHtmlContent.replace(/<a\s+aria-label="Get it from Microsoft".*?<\/a>/gi, ''); // Remove divs with class "x78zum5 xdt5ytf"
                cleanedHtmlContent = cleanedHtmlContent.replace(/<img\s+alt="Download on the App Store".*?>/gi, ''); // Remove the specified img element

                cleanedHtmlContent = cleanedHtmlContent.replace(/<link\s+data-default-icon="https:\/\/static\.cdninstagram\.com\/rsrc\.php\/v3\/yI\/r\/VsNE-OHk_8a\.png".*?>/gi, ''); // Remove the specified link element
                setHtmlContent(cleanedHtmlContent);
                setDataFlag(true);
                //test = test + 1;

            }
            else if (type === 'cursorPosition') {

            }
            else if (type === 'success') {
                console.log('success');
                setHtmlContent(' ');
                setSuccessFlag(true);


            }
            // Additional handling based on message type
        };

    }, [shouldPauseRendering])

    // Function to handle clicks within the rendered HTML content
    const handleClick = (event) => {
        event.preventDefault();
        const xpath = getElementXPath(event.target);
        lastClickedXPath.current = xpath;
        wsRef.current.send(JSON.stringify({
            type: 'click',
            xpath: xpath,
        }));
    };

    // Function to handle keydown events within the rendered HTML content
    const handleKeyDown = (event) => {
        const xpath = getElementXPath(event.target);
        let key = event.key;
        if (key === ' ') key = 'Space';
        if (key.length === 1 || key === 'Backspace' || key === 'Enter') {
            keypressBuffer.current.push({ xpath, key });
            debouncedSendKeyPresses();
        }
    };


    const handleFocus = (event) => {
        console.log('Textbox element highlighted:', event.target);
        if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
            previouslyHighlightedElementRef.current = event.target; // Keep track of the currently highlighted element
            event.target.style.background = 'white'; // Change background color to highlight
            event.target.style.zIndex = '999'; // Adjust z-index
            event.target.style.width = '100%';
            setShouldPauseRendering(true);
        }
    };


    // Function to handle input blur events within the rendered HTML content
    const handleInputBlur = (event) => {
        const xpath = getElementXPath(event.target);
        const value = event.target.value;
        console.log(`unselect: ${value}`);
        setShouldPauseRendering(false);
        //this.flag = true;

        /*
        wsRef.current.send(JSON.stringify({
            type: 'inputBlur',
            xpath: xpath,
            value: value,
        }));

         */


        // Check if the value for the given XPath has changed since the last send
        if (lastBlurValueRef.current[xpath] !== value) {
            wsRef.current.send(JSON.stringify({
                type: 'inputBlur',
                xpath,
                value
            }));

            // Update the last sent value for the XPath
            lastBlurValueRef.current[xpath] = value;
        }

    };

    const handleClickStart = () => {
        console.log('clicked start');
        setStartFlag(!startFlag);
    };

    const buttonStyle = {
        marginBottom: '10px',
        marginTop: '10px',
        padding: '10px 20px',
        backgroundColor: '#800080',
        color: 'white',
        border: 'none',
        borderRadius: '15px',
        cursor: 'pointer',
        fontSize: '20px',
        transition: 'background-color 0.3s',
    };

    const hoverButtonStyle = {
        ...buttonStyle,
        backgroundColor: '#a040a0', // Lighter shade of purple
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Center vertically
            alignItems: 'center', // Center horizontally
            height: '100vh', // Take up full viewport height
            textAlign: 'center' // Ensure text elements are centered as well
        }} onClick={handleClick} onKeyDown={handleKeyDown} onBlur={handleInputBlur} onFocus={handleFocus} tabIndex="0">

            {successFlag ? (
                <h1 style={{
                    color: '#4CAF50', // Softer shade of green
                    backgroundColor: '#EBF6F7', // Light background color for contrast
                    fontSize: '30px',
                    fontWeight: '600', // Bolder font for better visibility
                    textAlign: 'center',
                    padding: '20px', // Add some padding for space around the text
                    borderRadius: '10px', // Rounded corners for a modern look
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
                    border: '1px solid #4CAF50', // Soft green border
                    maxWidth: '80%', // Limit the maximum width
                    margin: '20px auto', // Center the message
                }}>
                    Success You Are Now Connected!
                </h1>
            ) : (
                startFlag ? (
                    <div>
                        <h1 style={{ color: 'white', fontSize: '30px', textAlign: 'center' }}>Tixta's Instagram Login</h1>
                        <h1 style={{ color: 'white', fontSize: '18px', textAlign: 'center', marginTop: '5px' }}>Let's Boost Your Instagram Influence!</h1>
                    </div>
                ) : null
            )}

            {startFlag ? (
                <button
                    className={"startup"}
                    onClick={handleClickStart}
                    style={isHovered ? hoverButtonStyle : buttonStyle}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    <img src="./images/components/instagram-icon.svg" alt="Instagram Icon" style={{ marginRight: '10px', marginBottom: '5px', width: '25px', height: '25px', filter: 'invert(1) brightness(100%)' }} />
                    Login with Instagram
                </button>
            ) : (
                !dataFlag ? <div className="spinner"></div> : (
                    successFlag ? null : (
                        <div style={{ width: '777px', height: '80vh' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    )
                )
            )}
        </div>
    );
};

export default PuppeteerEmulation;
