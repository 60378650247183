import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from "./components/Home";
import Instagram from "./components/Instagram";
import InstagramNew from "./components/InstagramNew";
import Tiktok from "./components/Tiktok";
import { Container } from "react-bootstrap";

function App() {
  return (
    <Container fluid className="p-0">
        <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} exact />
                    <Route path="/Instagram" element={<Instagram />} exact />
                    <Route path="/InstagramConnections" element={<InstagramNew />} exact />
                    <Route path="/Tiktok" element={<Tiktok />} exact />
                    <Route path="*" element={<Home />} replace />
                </Routes>
        </BrowserRouter>
    </Container>
  );
}

export default App;
