import React from "react";
import {Container, Navbar, Image} from "react-bootstrap";

import env1 from "./config";
let env = env1();

export default function Header() { // Default to false if not passed
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark" fixed="top" className={"user-select-none d-none d-lg-block"} style={{"fontSize":"12px"}}>
            <Container fluid className="p-0">
                <Navbar.Brand href="#home"><Image src={env.REACT_APP_LOGO_WHITE} width={50} height={50} fluid alt="Tixta" className="ms-3 animate__animated animate__lightSpeedInLeft" /></Navbar.Brand>
            </Container>
        </Navbar>
    );
}
