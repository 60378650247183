import { useEffect } from "react";

import env1 from "./config";
let env = env1();

function PageTitle({ pageName }) {
    useEffect(() => {
        if (pageName) {
            document.title = `${env.REACT_APP_TITLE} | ${pageName}`; // Set the title with prefix based on pageName
        } else {
            document.title = env.REACT_APP_TITLE; // Set the base title if no pageName is provided
        }
    }, [pageName]);
    return null;
}

export default PageTitle;
