import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import jsQR from 'jsqr';

import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Alert,
    Spinner, Image, InputGroup,
} from "react-bootstrap";

import Header from "./Header";
import Footer from "./Footer";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faThreads, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {faLock, faAngleDoubleLeft, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "./PageTitle";

import env1 from "./config";
let env = env1();

export default class Tiktok extends Component {
    state = {
        username: "",
        password: "",
        showAlert: false,
        loading: false,
        message: "",
        success: false,
        appName: window.location.hostname.substring(0).split(".")[0],
        imageData: null,
        showImage: false, // Add a new state variable
        emailFieldVisible: true,
        redirect: false,
        counter: 0,
        fail: false,
        interval: null,
        qrCodeLink: null,
        mobile: false,
    };


    componentDidMount() {
        this.checkIfMobile();
    }

    decodeQRCode(imageData) {
        if (!imageData || imageData.length === 0) {
            console.error('Invalid image data');
            return;
        }

        // Create an Image object
        const img = new Image();

        // Set up a canvas for cropping
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Ensure that the image has loaded before cropping
        img.onload = () => { // Use an arrow function here
            // Calculate the dimensions for a square crop (adjust as needed)
            const size = Math.min(img.width, img.height);
            const xOffset = (img.width - size) / 2;
            const yOffset = (img.height - size) / 2;

            // Crop the image to a square
            canvas.width = canvas.height = size;
            ctx.drawImage(img, xOffset, yOffset, size, size, 0, 0, size, size);

            // Prepare a Uint8ClampedArray for the pixel data
            const pixelData = new Uint8ClampedArray(size * size * 4);

            // Get pixel data from the cropped image
            const imgData = ctx.getImageData(0, 0, size, size);

            for (let i = 0; i < imgData.data.length; i++) {
                pixelData[i] = imgData.data[i];
            }

            // Set the width and height values to match the square size
            const width = size;
            const height = size;

            // Create an ImageData object using the cropped pixel data
            const image = new ImageData(pixelData, width, height);

            const code = jsQR(image.data, image.width, image.height);

            if (code) {
                // Return the decoded data
                this.setState({ qrCodeLink: code.data });
                console.log('QR Code Data:', code.data);
            } else {
                console.error('QR code not found or could not be decoded');
            }
        };

        // Set the source of the Image object to the base64 image data
        img.src = 'data:image/png;base64,' + imageData;
    }



    requestScreenshots = () => {
        const counterLimit = 28;
        this.setState({counter: 1 + this.state.counter});

        if (this.state.counter === counterLimit || this.state.fail){
            this.setState({success: false, loading: false, imageData: null, fail: true, message: 'An error has occurred, please try again or contact support. Redirecting.'}, () => {

                clearInterval(this.state.interval);


            })
            this.onShowAlert();
            setTimeout(() => { this.setState({ redirect: true})}, 5000 );
        }

        if (!this.state.success && (this.state.counter < counterLimit || !this.state.fail)) {
            fetch(`${env.REACT_APP_URL}/general/getScreenshots?username=${this.state.username}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            })
                .then((response) => response.json())
                .then((responseData) => {
                    this.setState({imageData: responseData.imageData, success: responseData.success, fail: responseData.fail});
                    if (this.state.mobile){
                        this.decodeQRCode(responseData.imageData);
                    }
                });
        }
    };

    componentWillUnmount() {
        // Clear the interval when the component is unmounted
        clearInterval(this.imageRefreshInterval);
    }

    handleUsername = (event) => {
        this.setState({ username: event.target.value });

    };
    handlePassword = (event) => {
        this.setState({ password: event.target.value });
    };

    handleDevice = () => {
        if (this.state.mobile){
            this.setState({ mobile: false });

        } else {
            this.setState({ mobile: true });
        }
    };

    onShowAlert = () => {
        this.setState({ showAlert: true }, () => {
            window.setTimeout(() => {
                this.setState({ showAlert: false });
            }, 30000);
        });
    };

    checkIfMobile = () => {
        const isMobile = window.innerWidth <= 768; // Adjust the width threshold as needed
        this.setState({ mobile: isMobile });
    };

    handleClick = (e) => {
        e.preventDefault();
        this.setState({imageData: null});
        this.setState({loading: true});
        if (this.state.username.length !== 0 ) {
            this.setState({ emailFieldVisible: false });
            console.log(this.state.appName, window.location.href.split("//")[1]);

            fetch(`${env.REACT_APP_URL}/general/tiktokQr`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json", // Use "application/json" if you're sending JSON data
                },
                body: JSON.stringify({ email: this.state.username, password: this.state.password }), // Send the email data in the request body
            })
                .then((response) => response.json())
                .then((responseData) => {

                    if (!responseData && !responseData.success) {
                        this.setState(
                            {
                                message: 'Error occurred. Please try again.',
                                success: false,

                            },
                            () => {
                                this.onShowAlert();
                            }
                        );
                        return;
                    }

                    // Set the base64 image data in the state
                    this.setState({showImage: true});
                    this.setState({ imageData: responseData.imageData });

                    if (responseData.imageData && this.state.mobile) {
                        this.decodeQRCode(responseData.imageData);
                    }

                    if (!this.state.success ){
                        // Request screenshots after first is sent
                        this.requestScreenshots();
                        this.setState({
                            interval: setInterval(this.requestScreenshots, 5000)
                        });
                    }

                    if (this.state.success) {
                        // Clear the interval when success is achieved
                        clearInterval(this.state.interval);
                    }

                    if (this.state.fail) {
                        this.setState({message: 'An error has occurred. Try again in 2 minutes or contact our support team. ', success: false });
                        this.onShowAlert()
                    }
                });
            this.setState({ showImage: true });
        } else {
            if (this.state.username.length === 0) {
                this.setState(
                    {
                        message: "Username cannot be empty",
                        loading: false,
                    },
                    () => {
                        this.onShowAlert();
                    }
                );
            } else {
                this.setState(
                    {
                        message: "Password cannot be empty",
                        loading: false,
                    },
                    () => {
                        this.onShowAlert();
                    }
                );
            }
        }
    };

    setRedirect = () => {
        this.setState({
            redirect: true,
        });
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Navigate to="/" />;
        }
    };
    render() {
        return (
            <>
                <PageTitle pageName="Login with TikTok"/>

                <Header />

                <Container id="tiktokContainer" className="user-select-none">
                    <Row className='d-flex align-items-center justify-content-center mb-5' style={{"minHeight":"750px"}}>
                        <Col lg='6' className='text-center'>
                            <h1 className="my-2 px-2">
                                <Image src={env.REACT_APP_LOGO_WHITE} width="auto" height="auto" style={{"maxHeight" : 200}} fluid alt="Tixta"/>
                                <br/>
                                <span className="text-info brandColorGradientLight fw-semibold fs-3">YOUR GATEWAY TO DIGITAL EXCELLENCE</span>
                            </h1>
                            <p className="px-3 text-white fw-lighter text-wrap">
                                Tixta is a cutting-edge tech company that specializes in transforming social media
                                profiles into powerful business tools and engaging organically with your target audience
                                on your behalf. Our mission is to help businesses and individuals amplify their presence
                                on on Social Media platforms.
                            </p>
                        </Col>

                        <Col lg="6">
                            <Card className='bg-body mt-5 shadow-sm border-4' style={{"minHeight": 455}}>
                                {this.renderRedirect()}
                                <Card.Header className="p-3">
                                    <span className="text-info brandColorGradientDark fw-semibold fs-4">Elevate Your Social Presence</span>
                                    <a href="#back" onClick={this.setRedirect}
                                       className="d-flex text-decoration-none mt-1">
                                        <span className="text-muted"><FontAwesomeIcon icon={faAngleDoubleLeft}
                                                                                      className="pe-1"/>Go Back</span>
                                    </a>
                                </Card.Header>
                                <Card.Body>
                                    {!this.state.success ? (
                                        <Card.Text className="text-muted">
                                            Please complete the steps below
                                        </Card.Text>
                                    ) : (
                                        ""
                                    )}
                                    {this.renderRedirect()}
                                    {this.state.showAlert && this.state.success ? (
                                        <Alert variant="success">{this.state.message}</Alert>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.showAlert && !this.state.success ? (
                                        <Alert variant="danger">{this.state.message}</Alert>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.success ? (
                                        <>
                                            {" "}
                                            <Alert variant="success" className={"text-bg-success"}>
                                                <FontAwesomeIcon icon={faCircleCheck} className={"pe-1 fs-6"}/>
                                                <span className={"fs-6"}>Integration completed successfully!</span>
                                            </Alert>
                                            <figure>
                                                <blockquote className="blockquote">
                                                    <p className={"fs-6"}>Please check your email inbox for a confirmation notification regarding
                                                        the successful integration.</p>
                                                </blockquote>
                                                <br/>
                                                <figcaption className="blockquote-footer">
                                                    If you don't see it in your inbox, please also look in your junk/spam
                                                    folder.
                                                </figcaption>
                                            </figure>
                                        </>
                                    ) : (
                                        <>
                                            <Form onSubmit={(e) => this.handleClick(e)}>
                                                {this.state.emailFieldVisible && (
                                                    <>
                                                        <InputGroup controlId="name" className="mb-4">
                                                            <Form.Control aria-label="Enter username or email address" name="name"
                                                                          type="text" placeholder="Username or email address"
                                                                          autoComplete="off" value={this.state.username} required
                                                                          onChange={(e) => this.handleUsername(e)}
                                                            />
                                                        </InputGroup>

                                                        <InputGroup controlId="password" className="mb-4">
                                                            <Form.Control aria-label="Enter pasword" name="password"
                                                                          type="password" placeholder="Password (optional)"
                                                                          autoComplete="off" value={this.state.password}
                                                                          onChange={(e) => this.handlePassword(e)}
                                                            />
                                                        </InputGroup>

                                                        <Form.Group controlId="mobileField" className="mb-3">
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="m-0"
                                                                label="Check if you are using a mobile device"
                                                                onChange={(e) => this.handleDevice(e)}
                                                                checked={this.state.mobile}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                )}

                                                {this.state.loading ? (
                                                    <Button type="submit" variant="dark" className="mb-2 w-100 fs-6 text-white" size="lg">
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                            className={"me-2"}
                                                        />
                                                        {" "}
                                                        {this.state.mobile ? "Generating Link..." : "Generating QR code..."}
                                                    </Button>
                                                ) : (
                                                    <Button type="submit" variant="dark" className="mb-2 w-100 fs-6 text-white" size="lg">
                                                        Login
                                                    </Button>
                                                )}
                                            </Form>

                                            {this.state.imageData && !this.state.mobile && (
                                                <>
                                                    <span className={"mb-2"}>
                                                        Please scan the QR code below to continue
                                                    </span>
                                                    <center>
                                                        <Image src={`data:image/png;base64,${this.state.imageData}`} width="auto" height="auto"
                                                               style={{"maxHeight" : 200}} className={"mt-2"} fluid alt="QR Code"/>
                                                        {this.state.qrCodeLink && (
                                                            <>
                                                                <br/>
                                                                <a href={this.state.qrCodeLink} target="_blank"
                                                                   rel="noopener noreferrer">
                                                                    Click here to open the link
                                                                </a>
                                                            </>
                                                        )}
                                                    </center>
                                                </>
                                            )}
                                            {this.state.imageData && this.state.mobile && (
                                                <>
                                                    {this.state.qrCodeLink && (
                                                        <Button
                                                            type="button"
                                                            variant="dark"
                                                            className="mb-2 w-100 fs-6 text-white"
                                                            size="lg"
                                                            onClick={() => window.open(this.state.qrCodeLink, '_blank', 'noopener noreferrer')}
                                                        >
                                                            Click to Connect
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </Card.Body>
                                <Card.Footer className="text-center text-muted p-3" style={{fontSize: 10}}>
                                    <FontAwesomeIcon icon={faLock} className="pe-1"/> All credentials and sensitive data are securely encrypted to safeguard your
                                    information. Rest assured, your <a href="https://tixta.com/privacy-policy/" className="text-muted">privacy</a> is our commitment.
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                    <Footer/>
            </>
        );
    }
}
