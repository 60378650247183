import React, {Component} from "react";
import { Navigate } from "react-router-dom";
import {Button, Card, Col, Container, Image, Row} from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faThreads,
    faInstagram,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import {
    faAngleDoubleLeft,
    faLock,
} from '@fortawesome/free-solid-svg-icons';

import Header from "./Header";
import Footer from "./Footer";
import PageTitle from "./PageTitle";

import env1 from "./config";
let env = env1();

//console.log(env.REACT_APP_URL);
//console.log(window.location.host);

export default class Home extends Component {
    state = {
        redirect: false,
        to: "",
    };
    setRedirect = (url) => {
        this.setState({
            redirect: true,
            to: url,
        });
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Navigate to={this.state.to}/>;
        }
    };

    render() {
        return (
            <>
                <PageTitle pageName="Login Page"/>

                <Header/>

                <Container id="homeContainer" className="user-select-none">
                    <Row className='d-flex align-items-center justify-content-center mb-5' style={{"minHeight":"750px"}}>
                        <Col lg='6' className='text-center'>
                            <h1 className="my-2 px-2">
                                <Image src={env.REACT_APP_LOGO_WHITE} width="auto" height="auto" style={{"maxHeight" : 200}} fluid alt="Tixta"/>
                                <br/>
                                <span className="text-info brandColorGradientLight fw-semibold fs-3">YOUR GATEWAY TO DIGITAL EXCELLENCE</span>
                            </h1>
                            <p className="px-3 text-white fw-lighter text-wrap">
                                Tixta is a cutting-edge tech company that specializes in transforming social media
                                profiles into powerful business tools and engaging organically with your target audience
                                on your behalf. Our mission is to help businesses and individuals amplify their presence
                                on Social Media platforms.
                            </p>
                        </Col>

                        <Col lg="6">
                            <Card className='bg-body mt-5 shadow-sm border-4' style={{"minHeight": 455}}>
                                {this.renderRedirect()}
                                <Card.Header className="p-3">
                                    <span className="text-info brandColorGradientDark fw-semibold fs-4">Elevate Your Social Presence</span>
                                    <a href="#back" onClick={this.setRedirect}
                                       className="d-flex text-decoration-none mt-1 invisible">
                                        <span className="text-muted"><FontAwesomeIcon icon={faAngleDoubleLeft}
                                                                                      className="pe-1"/>Go Back</span>
                                    </a>
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text className="text-muted">
                                        Choose your social media platform to proceed:
                                    </Card.Text>
                                    <Button variant="outline-light" className="mt-5 mb-3 w-100 fs-6 text-white customButton"
                                            size="lg"
                                            onClick={() => this.setRedirect("/Instagram")}
                                            style={{background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}}>
                                        <FontAwesomeIcon icon={faInstagram} className="pe-3"/>
                                        Continue with Instagram
                                    </Button>
                                    <Button variant="dark" className="mb-3 w-100 fs-6 text-white" size="lg"
                                            onClick={() => this.setRedirect("/Tiktok")}>
                                        <FontAwesomeIcon icon={faTiktok} className="pe-3"/>
                                        Continue with TikTok
                                    </Button>
                                    <Button variant="dark" className="mb-2 w-100 fs-6 text-white" size="lg" hidden>
                                        <FontAwesomeIcon icon={faThreads} className="pe-3"/>
                                        Continue with Threads
                                    </Button>

                                </Card.Body>
                                <Card.Footer className="text-center text-muted p-3" style={{fontSize: 10}}>
                                    <FontAwesomeIcon icon={faLock} className="pe-1"/> All credentials and sensitive data are securely encrypted to safeguard your
                                    information. Rest assured, your <a href="https://tixta.com/privacy-policy/" className="text-muted">privacy</a> is our commitment.
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Footer/>
            </>
        );
    }
}
