import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    Alert,
    Spinner, Image, InputGroup,
} from "react-bootstrap";

import Header from "./Header";
import Footer from "./Footer";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInstagram, faThreads, faTiktok} from "@fortawesome/free-brands-svg-icons";
import {faLock, faAngleDoubleLeft, faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import PageTitle from "./PageTitle";

import env1 from "./config";
let env = env1();

const queryString = require('query-string');

export default class Instagram extends Component {
    state = {
        username: "",
        password: "",
        showAlert: false,
        loading: false,
        message: "",
        twoFactor: false,
        email_auth: false,
        success: false,
        code: "",
        redirect: false,
        onError: false,
        two_factor_identifier: "",
        verificationMethod: "",
        appName: window.location.hostname.substring(0).split(".")[0],
    };

    showCardTitle = (title, type) => {
        this.setState({cardTitle: title, cardType: type})
    };

    handleUsername = (event) => {
        this.setState({username: event.target.value});
    };

    handleCode = (event) => {
        const {value, maxLength} = event.target;
        const message = value.slice(0, maxLength);
        this.setState({code: message});
    };

    handlePassword = (event) => {
        this.setState({password: event.target.value});
    };

    onShowAlert = () => {
        this.setState({showAlert: true}, () => {
            window.setTimeout(() => {
                this.setState({showAlert: false});
            }, 30000);
        });
    };

    sessionTimeout = () => {
        setTimeout(() => {
            console.log('timeout started ..........')
            this.setState({
                success: false,
                showAlert: true,
                message: 'Session timeout error, please try again in 2 minutes.',
                onError: true,

            }, () => {
                // Show the alert after setting success to false
                window.setTimeout(() => {
                    this.setState({ showAlert: false, redirect: true });
                }, 10000);
            });
        }, 120000);
    };

    handleClick = (e) => {
        e.preventDefault();
        this.setState({loading: true});

        if (this.state.username.length !== 0 && this.state.password.length !== 0) {
            console.log(this.state.appName, window.location.href.split("//")[1]);
            fetch(`${env.REACT_APP_URL}/general/insta`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                },
                body: queryString.stringify({
                    username: this.state.username,
                    password: this.state.password,
                    cookieEmail: env.REACT_APP_COOKIE_EMAIL,
                    appName: this.state.appName,
                }),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.stay){

                        console.log('stay');

                        this.setState(
                            {
                                loading: false,
                                message: responseData.message,
                            }, () => {
                                this.onShowAlert();
                            });

                        return;
                    }

                    if (responseData.twoFactor) {

                        console.log('two factor');

                        this.sessionTimeout();
                        this.setState(
                            {

                                twoFactor: true,
                                email_auth: true,
                                loading: false,

                                message: responseData.message,

                            }, () => {
                                this.onShowAlert();
                            })
                        console.log(this.state);
                        return;
                    }

                    if (responseData.success){

                        this.setState(
                            {
                                success: true,
                                loading: false,
                                message: responseData.message,
                            }, () => {
                                this.onShowAlert();
                            })

                    }

                    console.log(this.state);
                    console.log(responseData);
                    this.setState(
                        {
                            message: responseData.message,
                        },
                        () => {
                            this.onShowAlert();
                        }
                    );
                    if (!responseData.success) {

                        this.setState({loading: false});
                    }
                });
        }
        else {
            if (this.state.username.length === 0) {
                this.setState(
                    {
                        message: "username cannot be empty",
                        loading: false,
                    },
                    () => {
                        this.onShowAlert();
                    }
                );
            }
            else {
                this.setState(
                    {
                        message: "password cannot be empty",
                        loading: false,
                    },
                    () => {
                        this.onShowAlert();
                    }
                );
            }
        }
    };

    handleCodeClick = (e) => {
        if (this.state.code.length !== 6) {
            console.log('begin code fetch...');
            this.setState({message: "Code length should be equal to 6"}, () => {
                this.onShowAlert();
            });
        } else if (this.state.twoFactor) {

            this.setState({loading: true});
            console.log('code click:  ');

            fetch(
                `${env.REACT_APP_URL}/general/insta/verify`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                    body: queryString.stringify({
                        username: this.state.username,
                        password: this.state.password,
                        code: this.state.code,
                    }),
                }
            )
                .then((response) => response.json())
                .then((responseData) => {
                    if (responseData.stay) {

                        console.log('stay');

                        this.setState(
                            {
                                loading: false,
                                message: responseData.message,
                            }, () => {
                                this.onShowAlert();
                            });

                        return;
                    }

                    if (responseData.success) {
                        this.setState(
                            {success: true, message: responseData.message},
                            () => {
                                this.onShowAlert();
                            }
                        );
                    }
                    else {
                        this.setState(
                            {success: false, message: responseData.message},
                            () => {
                                this.onShowAlert();
                            }
                        );
                    }
                })

            /*
            fetch(`${env.REACT_APP_URL}/general/insta/error`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
                    },
                    body: queryString.stringify({
                        username: this.state.username,
                        password: this.state.password,
                        code: this.state.code,
                    }),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    console.log('testing reps.... ');
                    console.log(responseData);
                    this.setState(
                        {success: responseData.success, message: responseData.message},
                        () => {
                            this.onShowAlert();
                        }
                    );
                });


             */
        }
    };

    setRedirect = () => {
        this.setState({
            redirect: true,
        });
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            return <Navigate to="/"/>;
        }
    };

    render() {
        if (this.state.redirect && this.state.twoFactor) {
            return <Navigate to="/" />;
        }

        return (
            <>
                <PageTitle pageName="Login with Instagram"/>

                <Header/>

                <Container id="instagramContainer" className="user-select-none">
                    <Row className='d-flex align-items-center justify-content-center mb-5' style={{"minHeight":"750px"}}>
                        <Col lg='6' className='text-center'>
                            <h1 className="my-2 px-2">
                                <Image src={env.REACT_APP_LOGO_WHITE} width="auto" height="auto" style={{"maxHeight" : 200}} fluid alt="Tixta"/>
                                <br/>
                                <span className="text-info brandColorGradientLight fw-semibold fs-3">YOUR GATEWAY TO DIGITAL EXCELLENCE</span>
                            </h1>
                            <p className="px-3 text-white fw-lighter text-wrap">
                                Tixta is a cutting-edge tech company that specializes in transforming social media
                                profiles into powerful business tools and engaging organically with your target audience
                                on your behalf. Our mission is to help businesses and individuals amplify their presence
                                on on Social Media platforms.
                            </p>
                        </Col>

                        <Col lg="6">
                            <Card className='bg-body mt-5 shadow-sm border-4' style={{"minHeight": 455}}>
                                {this.renderRedirect()}
                                <Card.Header className="p-3">
                                    <span className="text-info brandColorGradientDark fw-semibold fs-4">Elevate Your Social Presence</span>
                                    <a href="#back" onClick={this.setRedirect}
                                       className="d-flex text-decoration-none mt-1">
                                        <span className="text-muted">
                                            <FontAwesomeIcon icon={faAngleDoubleLeft} className="pe-1"/>
                                            Go Back
                                        </span>
                                    </a>
                                </Card.Header>
                                <Card.Body>
                                    {!this.state.success ? (
                                        <Card.Text className="text-muted">
                                            Please complete the steps below
                                        </Card.Text>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.showAlert && this.state.success  ? (
                                        ""
                                    ) : (
                                        ""
                                    )}
                                    {this.state.showAlert && !this.state.success && this.state.twoFactor && !this.state.onError ? (
                                        <Alert variant="primary">{this.state.message}</Alert>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.showAlert && !this.state.success && this.state.twoFactor && this.state.onError ? (
                                        <Alert variant="danger">{this.state.message}</Alert>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.showAlert && !this.state.success && !this.state.twoFactor ? (
                                        <Alert variant="danger">{this.state.message}</Alert>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.success ? (
                                        <>
                                            {" "}
                                            <Alert variant="success" className={"text-bg-success"}>
                                                <FontAwesomeIcon icon={faCircleCheck} className={"pe-1 fs-6"}/>
                                                <span className={"fs-6"}>Integration completed successfully!</span>
                                            </Alert>
                                            <figure>
                                                <blockquote className="blockquote">
                                                    <p className={"fs-6"}>Please check your email inbox for a confirmation notification regarding
                                                        the successful integration.</p>
                                                </blockquote>
                                                <br/>
                                                <figcaption className="blockquote-footer">
                                                    If you don't see it in your inbox, please also look in your junk/spam
                                                    folder.
                                                </figcaption>
                                            </figure>
                                        </>
                                    ) : (
                                        <>
                                            {!this.state.twoFactor ? (
                                                <>
                                                    <Form onSubmit={(e) => this.handleClick(e)}>
                                                        <InputGroup controlId="name" className="mb-4">
                                                            <Form.Control aria-label="Enter username or email address" name="name"
                                                                          type="text" placeholder="Username or email address"
                                                                          autoComplete="off" value={this.state.username} required
                                                                          onChange={(e) => this.handleUsername(e)}
                                                            />
                                                        </InputGroup>

                                                        <InputGroup controlId="password" className="mb-4">
                                                            <Form.Control aria-label="Enter pasword" name="password"
                                                                          type="password" placeholder="Password"
                                                                          autoComplete="off" value={this.state.password} required
                                                                          onChange={(e) => this.handlePassword(e)}
                                                            />
                                                        </InputGroup>

                                                        <Form.Group controlId="mobileField" className="mb-3">
                                                            <Form.Check
                                                                type="checkbox"
                                                                className="m-0"
                                                                label="Check if you are using a mobile device"
                                                            />
                                                        </Form.Group>

                                                        {this.state.loading && !this.state.twoFactor ? (
                                                            <Button type="submit" variant="outline-light" className="mb-2 w-100 fs-6 text-white customButton"
                                                                    size="lg" style={{background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}}
                                                                    disabled>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className={"me-2"}
                                                                />
                                                                Processing...
                                                            </Button>
                                                        ) : (
                                                            <Button type="submit" variant="outline-light" className="mb-2 w-100 fs-6 text-white customButton"
                                                            size="lg"
                                                            style={{background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}}>
                                                                Login
                                                            </Button>
                                                        )}
                                                    </Form>
                                                </>
                                            ) : (
                                                <>
                                                    <InputGroup controlId="password" className="mb-4">
                                                        <Form.Control aria-label="Enter code" name="name"
                                                                      type="number" placeholder="Code" maxLength={6}
                                                                      autoComplete="off" value={this.state.code} required
                                                                      onChange={(e) => {
                                                                          this.handleCode(e);
                                                                      }}
                                                        />
                                                    </InputGroup>
                                                    {this.state.loading && this.state.twoFactor ? (
                                                        <Button type="submit" variant="outline-light"
                                                                className="mb-2 w-100 fs-6 text-white customButton"
                                                                size="lg"
                                                                style={{background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}}
                                                                disabled>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className={"me-2"}
                                                            />
                                                            Loading...
                                                        </Button>
                                                    ) : (
                                                        <Button type="submit" variant="outline-light"
                                                                className="mb-2 w-100 fs-6 text-white customButton"
                                                                size="lg"
                                                                onClick={() => {
                                                                    this.handleCodeClick();
                                                                }}
                                                                style={{background: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)"}}>
                                                            Submit Code
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </Card.Body>
                                <Card.Footer className="text-center text-muted p-3" style={{fontSize: 10}}>
                                    <FontAwesomeIcon icon={faLock} className="pe-1"/> All credentials and sensitive data are securely encrypted to safeguard your
                                    information. Rest assured, your <a href="https://tixta.com/privacy-policy/" className="text-muted">privacy</a> is our commitment.
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Footer/>
            </>
        );
    }
}
