import React from "react";
import {Row, Col, Container, Navbar} from "react-bootstrap";

export default function Footer() { // Default to false if not passed
    return (
        <Navbar expand="lg" bg="dark" data-bs-theme="dark" fixed="bottom" className={"user-select-none d-none d-lg-block"} style={{"fontSize":"12px"}}>
            <Container fluid>
                <Row>
                    <Col md="auto">
                        <a href="https://tixta.com/privacy-policy/" rel="noopener noreferrer" target="_blank" title="Privacy Policy" className="text-white fw-lighter text-decoration-none">
                            Privacy Policy
                        </a>
                    </Col>
                    <Col md="auto">
                        <a href="https://tixta.com/terms-of-use/" rel="noopener noreferrer" target="_blank" title="Terms of Service" className="text-white fw-lighter text-decoration-none">
                            Terms of Service
                        </a>
                    </Col>
                </Row>
                <Row>
                    <Col md="auto">
                        <a href="https://tixta.com" rel="noopener noreferrer" target="_blank" title="Tixta" className="text-white fw-lighter text-decoration-none">
                            Copyright &copy; 2024 Tixta.com | All Right Reserved
                        </a>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
}
